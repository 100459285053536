<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow" width="80%">
    <el-row type="flex" justify="start">
      <h4 class="label">Nome:</h4>
    </el-row>
    <base-input
      v-model="request_.name"
      :disabled="!CanEdit"
      type="text"
      size="large"
    />
    <el-row type="flex" justify="start">
      <h4 class="label">Cadastrado em:</h4>
    </el-row>
    <el-date-picker
      v-model="request_.created_at"
      type="date"
      disabled
      size="large"
      format="DD/MM/YYYY hh:mm:ss"
    >
    </el-date-picker>

    <el-row type="flex" justify="start">
      <h4 class="label">Empresa:</h4>
    </el-row>
    <el-select
      remote
      filterable
      clearable
      :loading="!firms"
      :remote-method="fetchFirms"
      value-key="uid"
      v-model="request_.firm_id"
      size="medium"
    >
      <el-option
        v-for="item in Firms"
        :key="item.uid"
        :label="item.name"
        :value="item.uid"
      >
      </el-option>
    </el-select>

    <el-row type="flex" justify="start">
      <h4 class="label">Urgencia:</h4>
    </el-row>
    <el-select v-model="request_.urgency">
      <el-option
        v-for="(item, value) in Urgency"
        :key="item"
        :label="item"
        :value="value"
      ></el-option>
    </el-select>
    <el-divider></el-divider>

    <el-row justify="between" align="bottom" :gutter="4">
      <el-col :md="14"
        ><el-row type="flex" justify="start">
          <h4 class="label">Produto:</h4>
        </el-row>
        <el-select
          v-model="selectedProduct"
          value-key="uid"
          filterable
          clearable
          remote
          reserve-keyword
          size="medium"
          :remote-method="(searchName) => fetchProducts(searchName)"
        >
          <el-option
            v-for="item in Products"
            :key="item?.uid"
            :label="item?.name"
            :value="item"
          >
          </el-option> </el-select
      ></el-col>
      <el-col :md="6"
        ><el-row type="flex" justify="start">
          <h4 class="label">Quantidade:</h4>
        </el-row>
        <el-row type="flex" :gutter="4" justify="space-between">
          <el-col>
            <base-input
              label="Quantidade:"
              type="money"
              hint="em kg."
              v-model="request_.quantity"
            >
            </base-input>
          </el-col> </el-row
      ></el-col>
      <el-col :md="4" :push="1">
        <el-button
          type="secondary"
          size="small"
          icon="el-icon-plus"
          :disabled="!CanEdit || !selectedProduct"
          @click="addProductToRequest"
        ></el-button>
      </el-col>
    </el-row>

    <el-divider />

    <el-table :data="RequestProducts" stripe style="width: 100%; z-index: 0">
      <el-table-column prop="product.name" label="Produto"></el-table-column>
      <el-table-column prop="quantity" label="Quantidade">
        <template v-if="CanUpdateProduct" #default="{ row: requestProduct }">
          <base-input
            v-model="requestProduct.quantity"
            v-on:keyup.enter="
              updateProduct({
                uid: requestProduct.uid,
                quantity: requestProduct.quantity,
              })
            "
            type="money"
            hint="em kg."
          ></base-input>
        </template>
        <template v-else #default="{ row: requestProduct }">{{
          requestProduct.quantity
        }}</template>
      </el-table-column>
      <el-table-column label="Tipo">
        <template #default="{ row: requestProduct }">
          {{
            requestProduct?.product?.type
              ? RequestProductTypesLabel[requestProduct.product.type]
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status">
        <template v-if="CanUpdateProduct" #header>
          <el-select
            placeholder="Atualizar tudo..."
            size="small"
            v-model="tempStatus"
            @change="
              updateProduct({status: $event})
            "
          >
            <el-option
              v-for="(label, status) in ProductRequestLabel"
              :key="status"
              :label="label"
              :value="status"
            />
          </el-select>
        </template>
        <template v-if="CanUpdateProduct" #default="{ row: requestProduct }">
          <el-select
            v-model="requestProduct.status"
            @change="
              updateProduct({
                uid: requestProduct.uid,
                status: requestProduct.status,
              })
            "
          >
            <el-option
              v-for="(label, status) in ProductRequestLabel"
              :key="status"
              :label="label"
              :value="status"
            />
          </el-select>
        </template>
        <template v-else #default="{ row: requestProduct }">
          {{ ProductRequestLabel[requestProduct.status] }}
        </template>
      </el-table-column>
      <el-table-column label="Ações" align="center" fixed="right" width="100">
        <template #default="scope">
          <el-button
            v-if="CanEdit"
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="request_.products.splice(scope.$index, 1)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-row>
          <el-button
            type="text"
            v-if="!request_.is_ready"
            @click="updateRequest({ is_ready: true })"
            >Marcar como concluido</el-button
          >
          <el-button
            type="primary"
            :isLoading="isLoadingSave"
            class="button"
            :disabled="!CanEdit"
            @click="save"
            >Salvar</el-button
          >
        </el-row>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import ProductService from "../../services/products";
import RequestService from "../../services/requests";
import FirmService from "../../services/firms";
import { notifySuccess, notifyError } from "../../utils/notifiers";
import { cloneDeep } from "lodash";
export default {
  props: ["request", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      request_: cloneDeep(this.request || {}),
      standards: null,
      tempStatus: '',
      selectedProduct: null,
      firms: null,
      products: null,
      isLoadingSave: false,
    };
  },
  mounted() {
    setInterval(() => {
      if (this.isNew) this.request_.created_at = new Date();
    }, 1000);
  },
  watch: {
    selectedProduct(product) {
      if (product) {
        this.request_.product_id = product.productId;
      }
    },
    request() {
      if (this.request) this.request_ = cloneDeep(this.request);
      else this.request_ = {};
      if (this.request_.firm?.uid)
        this.request_.firm_id = this.request_.firm?.uid;
    },
    showModal(show) {
      if (show) {
        this.fetchFirms(true);
        this.selectedProduct = null;

        if (this.request) this.request_ = cloneDeep(this.request);
      }
    },
  },
  computed: {
    StatusOfProducts(){
      const productStatus = this.RequestProducts.reduce((total, product) => {
        total.add(product.status);
        return total;
      }, new Set());

      return productStatus.size > 1 ? '' : Array.from(productStatus.keys()).at(0);
    },
    RequestProductTypesLabel() {
      return {
        consumption: "Consumo",
        service: "Revenda",
      };
    },
    CanEdit() {
      return this.isNew || !this.request_.is_ready;
    },
    CanUpdateProduct() {
      return this.request_.status === "accepted";
    },
    ProductRequestLabel() {
      return {
        pending: "Pendente",
        bought: "Comprado",
        "not available": "Não disponível",
        "delivery": "Em entrega",
        "delivered": "Entregue"
      };
    },
    Urgency() {
      return {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
      };
    },
    Firms() {
      return this.firms?.filter(({ type }) => type === "shop") || [];
    },
    isNew() {
      return !this.request;
    },
    Products() {
      return this.products || [];
    },
    RequestProducts() {
      return this.request_.products || [];
    },
    CategoryName() {
      return this?.request_?.category?.name || "";
    },
    Standards() {
      return this.standards?.map((s) => s.value) || [];
    },
    ModalTitle() {
      return "uid" in (this.request_ || {})
        ? "Editar Pedido de Compra"
        : "Cadastrar Pedido de Compra";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async updateProduct(product) {
      const self = this;
      if(!('uid' in product)){
        this.RequestProducts.forEach(p => {
          self.updateProduct({uid: p.uid, ...product})
          Object.assign(p, product);
        })
        return;
      }

      const { error } = await RequestService(this.request_.uid)
        .Products(product.uid)
        .update(product);

      if (error) {
        notifyError("Ocorreu um erro ao atualizar o produto");
        return;
      }
    },
    addProductToRequest() {
      if (!this.request_.products) this.request_.products = [];
      this.request_.products?.push({
        product: this.selectedProduct,
        quantity: this.request_.quantity,
      });
      this.request_.quantity = null;
      this.selectedProduct = null;
    },
    async fetchFirms() {
      const { firms } = await FirmService().index({ type: "shop" });

      if (firms) {
        this.firms = firms;
      }
    },
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateRequest() : this.createRequest();
    },
    async createRequest() {
      this.isLoadingSave = true;

      const { error } = await RequestService().create({
        ...this.request_,
        type: "reseller",
        products: this.RequestProducts,
      });

      if (error) {
        notifyError("Ocorreu um erro ao criar o pedido de compra");
      } else {
        notifySuccess("Pedido de compra criado com sucesso");
        this.$emit("should-update");
        this.$emit("close-modal");
      }

      this.isLoadingSave = false;
    },
    async fetchProducts(searchName) {
      const { products } = await ProductService().index({
        searchName,
        type: "reseller",
        category_id: "25a4382e-820d-4dec-a6c9-9a695b781c9e",
      });
      if (products) {
        this.products = products;
      }
    },
    async updateRequest(rq) {
      this.isLoadingSave = true;

      if (rq) {
        this.request_ = {
          ...this.request_,
          ...rq,
        };
      }

      const { error } = await RequestService(this.request_.uid).update({
        ...this.request_,
        products: this.RequestProducts,
      });

      if (error) {
        notifyError("Ocorreu um erro ao atualizar o pedido de compra");
        return;
      }

      notifySuccess("Pedido de compra atualizado com sucesso");
      this.$emit("should-update");
      this.$emit("close-modal");

      this.isLoadingSave = false;
    },
  },
  name: "ProductEntryModal",
};
</script>
<style scoped>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-switch {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.label {
  margin-top: 10px;
  margin-bottom: 2px;
}
</style>
