<template>
  <el-dialog
    title="Detalhes do caixa da fabrica"
    v-model="shouldShow"
    destroy-on-close
    width="85%"
  >
    <el-descriptions
      title="Fechamento do Caixa"
      direction="vertical"
      border
      :column="7"
    >
      <el-descriptions-item>
        <template #label> Dia de Referência: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ dateFormatter.format(new Date(cashier?.reference)) }}
        </div>
        <base-date-picker
          v-else
          v-model="cashier_.reference"
          type="money"
          v-on:blur="updateSilent()"
        ></base-date-picker>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Moeda: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.coin)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.coin"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Dinheiro: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.money)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.money"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cartão de Crédito: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.credit)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.credit"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cartão de Débito: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.debit)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.debit"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> PIX: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.pix)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.pix"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cheque: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.checks)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.checks"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Ticket: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.ticket)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.ticket"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Entregas: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.delivery)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.delivery"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Descontos: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.discount)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.discount"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Despesas: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.expenses)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.expenses"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Convênio: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.debts)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.debts"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>

      <el-descriptions-item>
        <template #label> Cancelamentos: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.canceled)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.canceled"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Sangria: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.withdrawal)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.withdrawal"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Total do Caixa: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.total_cashier)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.total_cashier"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
    </el-descriptions>

    <div class="mt-2"></div>

    <el-descriptions
      title="Resultados dos Pedidos"
      direction="vertical"
      border
      :column="7"
    >
      <el-descriptions-item>
        <template #label> Caixa Inicial: </template>
        <div>
          {{ currencyFormatter.format(Number(cashier?.money_start)) }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> PIX: </template>
        <div>
          {{ currencyFormatter.format(sumOrderPaymentByType("pix")) }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Dinheiro: </template>
        <div>
          {{ currencyFormatter.format(sumOrderPaymentByType("money")) }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cartão de Crédito: </template>
        <div>
          {{ currencyFormatter.format(sumOrderPaymentByType("credit")) }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cartão de Débito: </template>
        <div>
          {{ currencyFormatter.format(sumOrderPaymentByType("debit")) }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Boleto: </template>
        <div>
          {{ currencyFormatter.format(sumOrderPaymentByType("billet")) }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cheque: </template>
        <div>
          {{ currencyFormatter.format(sumOrderPaymentByType("check")) }}
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <el-table
      :cell-style="() => 'text-align:center;'"
      :row-class-name="({ row }) => (row.status === 'paid' ? `success` : '')"
      :data="OrderPayments"
      style="width: 100%; z-index: 0"
    >
      <el-table-column label="#" prop="order.seq"></el-table-column>
      <el-table-column label="Nº" prop="order.code"></el-table-column>
      <el-table-column
        label="cliente"
        :formatter="
          ({ order }) => order.client.legal?.social_name || order.client.name
        "
      ></el-table-column>
      <el-table-column
        label="valor pago"
        :formatter="({ amount }) => currencyFormatter.format(amount)"
      ></el-table-column>
      <el-table-column
        label="faturamento"
        prop="payment_method_formatted"
      ></el-table-column>
      <el-table-column
        label="estado"
        prop="order.status_formatted"
      ></el-table-column>
    </el-table>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button type="primary" class="button" @click="acceptClosing">
          Conferir e finalizar {{ PaidOrders.length }} pedidos
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { ElNotification, ElMessageBox } from "element-plus";
import OrderService from "../../services/orders";
import { currencyFormatter, dateFormatter } from "../../utils/formatters";
import { notifySuccess } from "../../utils/notifiers";

export default {
  props: ["cashier", "showModal"],
  emits: ["close-modal", "should-update"],
  data() {
    return {
      firms: null,
      cashier_: this.cashier || {},
      currencyFormatter,
      dateFormatter,
      orders: null,
    };
  },
  mounted() {
    this.$watch(
      function () {
        return Object.values(this.cashier_);
      },
      function () {
        this.updateTotal();
      },
      { deep: true }
    );
  },
  watch: {
    cashier(v) {
      this.cashier_ = v;
      if (v.firm) {
        this.firms = [v.firm];
        this.cashier_.firm_id = v.firm?.uid;
      }

      this.fetchOrders();
    },
  },
  computed: {
    Orders() {
      return this.orders?.map(({ order }) => order) || [];
    },
    PaidOrders() {
      return this.Orders.filter((o) => o.status === "paid");
    },
    OrderPayments() {
      return this.orders || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    isNew() {
      return !("uid" in (this.event || {}));
    },
  },
  methods: {
    async fetchOrders() {
      const refDate = new Date(
        this.cashier.reference || this.cashier.created_at
      );
      refDate.setUTCHours(0, 0, 0);
      const refDay = Math.round(refDate.valueOf() / 8.64e7 + 1);

      const { payments } = await OrderService().Payments().list({
        paid_at_day: refDay,
        orderStatus: "paid",
        orderBy: "clientName",
      });

      if (payments?.length) this.orders = payments.filter(p => p.payment_method !== "billet");
      else this.orders = [];
    },
    fetchFirms(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}firms`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
            this.$emit("fetch-firms", json);
          });
    },
    updateSilent() {
      this.updateCashier(this.cashier_, true);
    },
    shouldAllowEditCashierInput() {
      return ["checked"].includes(this.cashier_.status);
    },
    checkOrderPayments() {
      return this.OrderPayments.map((orderPayment) =>
        OrderService(orderPayment.order?.uid)
          .Payments(orderPayment.uid)
          .update({
            checked_by: this.$store.state.user.uid,
          })
      );
    },
    async acceptClosing() {
      this.updateCashier({
        uid: this.cashier?.uid,
        status: "completed",
        checked_by: this.$store.state?.user?.uid,
      });

      const queries = this.checkOrderPayments();

      await Promise.all(queries);

      notifySuccess(
        "Caixa conferido com sucesso",
        "Os pedidos marcados como pago foram finalizados"
      );
    },
    async rejectCashier() {
      const rejection_reason = await ElMessageBox.prompt(
        "Insira uma breve descrição do motivo da rejeição",
        "Motivo da rejeição",
        {
          confirmButtonText: "Rejeitar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((v) => v.value)
        .catch(() => {});
      this.updateCashier({
        ...(this.cashier_ || {}),
        status: "rejected",
        rejection_reason,
      });
    },
    sumOrderPaymentByType(method, payments) {
      const orderPayments = payments || this.OrderPayments;
      return orderPayments.reduce((sum, order) => {
        const isSamePaymentMethod = order.payment_method === method;

        if (isSamePaymentMethod) sum += Number(order.amount);

        return sum;
      }, 0);
    },
    updateTotal() {
      let t = 0;

      t += Number(this.cashier?.money_start) || 0;
      t += Number(this.cashier_?.delivery) || 0;
      t -= Number(this.cashier_?.debts) || 0;
      t -= Number(this.cashier_?.expenses) || 0;
      t -= Number(this.cashier_?.discount) || 0;
      //t -= Number(this.cashier_?.start) || 0;
      //t += Number(this.cashier_?.canceled) || 0;
      t += Number(this.cashier_?.checks) || 0;
      t += Number(this.cashier_?.coin) || 0;
      t += Number(this.cashier_?.money) || 0;
      t += Number(this.cashier_?.credit) || 0;
      t += Number(this.cashier_?.ticket) || 0;
      t += Number(this.cashier_?.debit) || 0;
      t += Number(this.cashier_?.pix) || 0;
      this.cashier_.total_cashier = Math.max(t, 0);
    },
    updateCashier(cashier, silent = false) {
      if (cashier)
        fetch(`${this.$store.state.apiUrl}cashiers/${cashier.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify(cashier),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            () => {
              if (!silent) {
                this.$emit("should-update");
                ElNotification.success({
                  title: `Fechamento de caixa alterado com sucesso!`,
                  position: "bottom-right",
                });
                this.$emit("close-modal");
              }
            },
            (e) => {
              if (!silent) {
                ElNotification.error({
                  title: "Ocorreu um erro",
                  message: e.message,
                  position: "bottom-right",
                });
              }
            }
          )
          .finally(() => (this.isLoadingSave = false));
    },
  },
  name: "CashierCloseInfoFactoryModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-1 {
  margin-top: 4px;
}

.stretch-width {
  width: 100% !important;
}
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
